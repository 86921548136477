<template>
  <div class="securityAdvisoryAlls">
    <div class="securityAdvisoryFirst">
      <div class="securityAdvisoryFirst1">
        <div class="securityAdvisoryFirst1Left">
          <h1>信息安全咨询服务</h1>
          <p>
            信息安全咨询服务根据国际、国内的相关标准，为企事业单位建立完整的信息安全管理与信息安全技术体系。通过差距分析、风险评估、安全规划等多种手段，对组织控制要素提出合理的控制方案，提升组织抵御和防范各种信息安全攻击和事件的能力。
          </p>
        </div>
        <div class="securityAdvisoryFirst1Right">
          <img src="../../../../assets/img/sofaFW/5.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="securityAdvisorySecond">
      <div class="securityAdvisorySecondLeft">
        <img
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.hatyw.com%2Fupload%2Fimages%2F20160225_113100.jpg&refer=http%3A%2F%2Fwww.hatyw.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672394198&t=feb5d369ea60c4ee8a8e934c7e5e7bc3"
          alt=""
        />
      </div>
      <div class="securityAdvisorySecondRight">
        <p>
          设计数据安全策略以支持用户的业务策略。方案制定时从用户业务角度出发，拒绝通用安全设计。配置并扩展所有功能用例的数据，包括事务分析和报告、数据科学、数字和运营等方面。
        </p>
        <p>
          御之安科技提供的信息安全咨询服务主要以信息系统安全等级保护相关标准为主要依据，结合BS17799、ISO/IEC
          27001:2005等国际标准，通过信息系统等级差距分析、等级保护基本要求解读、风险评估等手段，对新建系统提供信息安全等级级别咨询、新建系统等级符合性咨询、已建系统等级保护整改咨询、信息产品等级保护符合性咨询、信息安全应急响应咨询、信息安全管理体系建设咨询。
        </p>
      </div>
    </div>
    <div class="securityAdvisoryThird">
      <div class="securityAdvisoryThirdLeft">
        <p>
          应急响应咨询可以建立科学合理的应急响应预案，通过预案的演练提升系统对于各类安全事件的防御能力。
        </p>
        <p>
          建立完善的信息安全管理体系，对内从管理角度防止信息系统出现安全事故或事件，对外树立信息系统可靠性形象，满足业务要求，提高自身的竞争能力。
        </p>
      </div>
      <div class="securityAdvisoryThirdRight">
        <img
          src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnwzimg.wezhan.cn%2Fcontents%2Fsitefiles2037%2F10188828%2Fimages%2F18437686.png&refer=http%3A%2F%2Fnwzimg.wezhan.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672393196&t=99f4443653d60f8f8ace082c5649437d"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.securityAdvisoryAlls {
  width: 100%;
  .securityAdvisoryFirst {
    background: #f1f5ff;
    .securityAdvisoryFirst1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .securityAdvisoryFirst1Left {
        width: 30%;
        h1 {
          font-size: 35px;
        }
        p {
          font-size: 18px;
          margin-top: 10px;
        }
      }
      .securityAdvisoryFirst1Right {
        margin-left: 50px;
      }
    }
  }
  .securityAdvisorySecond {
    margin-top: 40px;
    background: #f1f5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .securityAdvisorySecondLeft {
      img {
        width: 400px;
        height: 400px;
      }
    }
    .securityAdvisorySecondRight {
      width: 30%;
      margin-left: 90px;
      p {
        text-indent: 2rem;
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
  .securityAdvisoryThird {
    margin-top: 40px;
    background: #f1f5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .securityAdvisoryThirdLeft {
      width: 30%;

      p {
        text-indent: 2rem;
        font-size: 18px;
        margin-top: 10px;
      }
    }
    .securityAdvisoryThirdRight {
      margin-left: 90px;
      img {
        width: 500px;
      }
    }
  }
}
</style>